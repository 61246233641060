module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"AVZpro","start_url":"/","background_color":"#663399","theme_color":"#0d6efd","display":"minimal-ui","icon":"static/icons/icon-512x512.png","icons":[{"src":"static/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"static/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"static/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"static/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"static/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"static/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"static/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3f549a9379e8519eb5c8f33548a5a25"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ru","uk","en","de"],"defaultLanguage":"ru","fallbackLanguage":"uk","debug":false,"siteUrl":"http://localhost:8000/","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-166314532-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
